import { SET_MESSAGE_SUCCESS, SET_MESSAGE_ERROR, CLEAR_MESSAGE, SET_MESSAGE_IMPORT_ERROR } from "../actions/types";

const initialState = {};

export default function (state = initialState, action) {
  const { type, payload } = action;

  switch (type) {
    case SET_MESSAGE_SUCCESS:
      return { message: payload, type:"SUCCESS" };

    case SET_MESSAGE_ERROR:
      return { message: payload.map((val,index)=>{ return val.displayMessage}).join('<br>'), type:"ERROR" };
 
    case SET_MESSAGE_IMPORT_ERROR:
      return { message: payload.invalidData.map((val,index)=>{ return val.errorMsg}).join('<br/>'), type:"IMPORT_ERROR" };
   
    case CLEAR_MESSAGE:
      return { message: "", type:"" };

    default:
      return state;
  }
}
