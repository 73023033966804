export const LOGIN_SUCCESS = "LOGIN_SUCCESS";
export const LOGIN_FAIL = "LOGIN_FAIL";
export const LOGOUT = "LOGOUT";
export const SET_MESSAGE_ERROR = "SET_MESSAGE_ERROR";
export const SET_MESSAGE_SUCCESS = "SET_MESSAGE_SUCCESS";
export const CLEAR_MESSAGE = "CLEAR_MESSAGE";
export const SET_LANGUAGE = "SET_LANGUAGE";
export const REGISTER_SUCCESS = "REGISTER_SUCCESS";
export const REGISTER_FAIL = "REGISTER_FAIL";
export const GET_ORG = "GET_ORG";
export const ADD_ORG = "ADD_ORG";
export const DETAIL_ORG = "DETAIL_ORG";  
export const EDIT_ORG = "EDIT_ORG";
export const DELETE_ORG = "DELETE_ORG";
export const GET_OBSERVATION = "GET_OBSERVATION";
export const ADD_OBSERVATION = "ADD_OBSERVATION";
export const EDIT_OBSERVATION = "EDIT_OBSERVATION";
export const DELETE_OBSERVATION = "DELETE_OBSERVATION";
export const GET_USER = "GET_USER";
export const ADD_USER = "ADD_USER";
export const EDIT_USER = "EDIT_USER";
export const DELETE_USER = "DELETE_USER";
export const GET_SPECIES = "GET_SPECIES";
export const GET_FINTYPE = "GET_FINTYPE";
export const GET_FINVIEW = "GET_FINVIEW";
export const GET_LOCATION = "GET_LOCATION";
export const GET_COMMONNAME = "GET_COMMONNAME";
export const GET_TRAINING = "GET_TRAINING"; 
export const EDIT_TRAINING = "EDIT_TRAINING";
export const DELETE_TRAINING = "DELETE_TRAINING";
export const GET_ORGLIST = "GET_ORGLIST";
export const GET_ROLELIST = "GET_ROLELIST";
export const SET_LANG = "SET_LANG";
export const SET_MESSAGE_IMPORT_ERROR = "SET_MESSAGE_IMPORT_ERROR";
