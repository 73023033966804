import React, {useState, useEffect} from 'react'; 
 
function NotFound() { 
   return (
    <div style={{textAlign:'center'}}>
      <h1>404 Not Found.</h1> 
    </div>
  );
}

export default NotFound;
