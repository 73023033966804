export const environment = 'development';
const development = {
   apiurl:'https://niswebservices.azurewebsites.net/api/',
   imgeurl:'',
   baseurl:'',
   roles:{
     "superAdmin":"super_admin",
     "orgAdmin":"org_admin",
     "orgViewer":"org_viewer",
     "regularUser":"regular_user",
     "trainingUser":"training_user",
   },
   sampleImportTaxonomy:'https://nisportalapi.blob.core.windows.net/demo-files/Taxonomies-Import-Demo.csv',
   sampleImportTraining:'https://nisportalapi.blob.core.windows.net/demo-files/Training-Import-Demo.csv',
   cookieExpirationDays:'30',
};

const production = {
  apiurl:'https://nismobilebackendapi.azurewebsites.net/api/',
  imgeurl:'',
  baseurl:'',
  roles:{
    "superAdmin":"super_admin",
    "orgAdmin":"org_admin",
    "orgViewer":"org_viewer",
    "regularUser":"regular_user",
    "trainingUser":"training_user",
  },
  sampleImportTaxonomy:'https://nisportalapi.blob.core.windows.net/demo-files/Taxonomies-Import-Demo.csv',
  sampleImportTraining:'https://nisportalapi.blob.core.windows.net/demo-files/Training-Import-Demo.csv',
  cookieExpirationDays:'30',
}

const config =  environment === 'production' ? production : development;
export default config;