import React, {useState, Suspense, lazy, useEffect} from 'react'; 
import { useDispatch, useSelector } from "react-redux"; 
import "semantic-ui-css/semantic.min.css";
import './App.css';
import { BrowserRouter, Routes, Route, Link, Navigate } from "react-router-dom";
import { useTranslation } from "react-i18next";
import Loading from "./components/Loading";
import NotFound from "./components/NotFound";
import config from "./config"; 
  
const Login = lazy(() => import('./components/Pages/Login'));  
const ForgotPassword = lazy(() => import('./components/Pages/ForgotPassword')); 
const Observation = lazy(() => import('./components/Pages/Observation/Observation'));  
const ImageDetail = lazy(() => import('./components/Pages/Observation/ImageDetail')); 
const Organizations = lazy(() => import('./components/Pages/Organization/Organization'));
const Training = lazy(() => import('./components/Pages/Training/Training')); 
const TrainingDetail =  lazy(() => import('./components/Pages/Training/Trainingdetail'));
const EditTraining = lazy(() => import('./components/Pages/Training/EditTraining')); 
const Addorg = lazy(() => import('./components/Pages/Organization/AddOrganization')); 
const Editorg = lazy(() => import('./components/Pages/Organization/EditOrganization'));
const User = lazy(() => import('./components/Pages/User/User')); 
const AddUser = lazy(() => import('./components/Pages/User/AddUser')); 
const EditUser = lazy(() => import('./components/Pages/User/EditUser')); 
const SetPassword = lazy(() => import('./components/Pages/SetPassword')); 
const Taxonomy = lazy(() => import('./components/Pages/Taxonomy/Taxonomy'));
const Addtaxonomy = lazy(() => import('./components/Pages/Taxonomy/AddTaxonomy'));
const Edittaxonomy = lazy(() => import('./components/Pages/Taxonomy/EditTaxonomy'));
const ImportTaxonomy = lazy(() => import('./components/Pages/Taxonomy/ImportTaxonomy'));
const ImportTraining = lazy(() => import('./components/Pages/Training/ImportTraining')); 
const Registration = lazy(() => import('./components/Pages/Registration'));

function App() {  
  const { isLoggedIn, user, lang } = useSelector(state => state.auth); 
  const { message, type } = useSelector(state => state.message);
  const { t, i18n } = useTranslation();

  useEffect(() => {
    i18n.changeLanguage(lang); 
  }, [lang]);

  

  return (
    <BrowserRouter> 
        <Suspense fallback={<Loading/>}>
            <Routes> 
              <Route path='/' element={<Login/>} />
              <Route path='/login' element={<Login/>} />
              <Route path='/forgotpassword' element={<ForgotPassword/>} />
              <Route path='/setpassword/:id' element={<SetPassword/>} />
              <Route path='/setpassword/:id/:id' element={<SetPassword/>} />
              <Route path='/registration' element={<Registration/>} />

              <Route path='/observations' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgViewer)!=-1 || 
                 user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1  || 
                 user.data[0].roles.findIndex(x => x.slug ===config.roles.regularUser)!=-1) 
                ?<Observation/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />
 
              <Route path='/imagedetails/:id' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgViewer)!=-1 || 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 || 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.regularUser)!=-1) 
                ?<ImageDetail/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />
              
             

              <Route path='/users' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1 ) 
                ?<User/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/adduser' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1 ) 
                ?<AddUser/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />
              
              <Route path='/edituser/:id' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1 ) 
                ?<EditUser/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />
 
              <Route path='/training' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.trainingUser)!=-1 ) 
                ?<Training/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />
 
              <Route path='/trainingdetails/:id' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.trainingUser)!=-1)  
                ?<TrainingDetail/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

             <Route path='/edittraining/:id' 
              element={isLoggedIn==true && 
                (user.data[0].roles.findIndex(x => x.slug ===config.roles.orgAdmin)!=-1 ||
                user.data[0].roles.findIndex(x => x.slug ===config.roles.trainingUser)!=-1 ) 
                ?<EditTraining/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              {/* <Route path='/importtraining' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1
                ?<ImportTraining/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              /> */}

              <Route path='/organizations' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1 
                ?<Organizations/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/addorg' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1  
                ?<Addorg/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/editorg/:id' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1
                ?<Editorg/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />


              <Route path='/taxonomy' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1 
                ?<Taxonomy/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/addtaxonomy' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1  
                ?<Addtaxonomy/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/edittaxonomy/:id' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1
                ?<Edittaxonomy/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              <Route path='/importtaxonomy' 
              element={isLoggedIn==true && 
                user.data[0].roles.findIndex(x => x.slug ===config.roles.superAdmin)!=-1
                ?<ImportTaxonomy/>
                :isLoggedIn==true
                ?<NotFound/>
                :<Navigate to={{ pathname: '/login' }} />} 
              />

              
              <Route path='*' element={<NotFound/>} />

            </Routes>
        
          </Suspense>
         
    </BrowserRouter>
  );
}

export default App;
